.dash-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;

    width: 100vh;
    background-color: transparent;
    flex: 1 0 auto;
}

.dash-column{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;

    background-color: var(--white);
    flex: 1 1 auto;
}