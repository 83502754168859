.default-theme {
  --primary-color: #0A9396;
  --primary-color-dark: #005F73;
  --primary-color-light: #94D2BD;

  --secondary-color-light: #EE9B00;
  --secondary-color: #CA6702;
  --secondary-color-dark: #BB3E03;

  --background-color-dark: #001219;
  --background-color-light: #E9D8A6;

  --tertiary-color: #AE2012;
  --tertiary-color-light: #fcac87;

  --disabled-color: #9B2226;
  --white: #ffffff;
  --off-white: #f0f0f0;
  --light-grey: #dedede;
  --grey: #9a9a9a;
  --dark-grey: #333;

  --text-color: #404040;
  --light-text-color: #ababab;
  --alt-text-color: #404040;
}