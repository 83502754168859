.signup-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 350px;
    height:525px;

    box-shadow: 0px 0px 10px rgb(14, 13, 13);
    background-color: var(--white);
}