.reservation-wrapper {
  background-color: var(--white);

  box-shadow: 0px 0px 10px rgb(14, 13, 13);
}

.reservation-container {
  padding: 0em 1.5em 1.5em 1.5em;
}

.reservation-header{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: normal;
  
  height: 32px;
  width: 100%;
}

.reservationButtons > button {
  margin: 5px;
}

.close-icon {
  width: 32px;
  height: 32px;
  opacity: 0.3;
  background-color: #fff;
  border: none;
  
  margin: 0;
  padding: 0;
}

.close-icon:hover{
  opacity: 1;
}

.close-icon:active, .close-icon:hover, .close-icon:focus, .close-icon:visited{
  background-color: #fff;
  border: none;
}

.close-icon:before{
  content: "\00d7";
  color: #111;
  font-size: 25pt;
  font-weight: 300;
  font-family: Arial, sans-serif;
}