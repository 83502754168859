.header-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    
    height: 75px;
    flex-grow: 0;
    flex: 0 0 auto;
    width: 100%;
    background-color: var(--primary-color-dark);
}

.header-wrapper > a{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;

    flex: 1 0 auto;
    color: var(--off-white);
    max-width: 200px;
    font-size: 30px;
}

.header-wrapper > a:hover{
    text-decoration: none;
    color: var(--secondary-color-light);
}

.header-wrapper > a:active{
    text-decoration: none;
}

.header-filler-front {
    max-width: 450px;
    flex: 1 1 auto;
}

.header-filler-back {
    max-width: 100%-850px;
    flex: 1 1 auto;
}