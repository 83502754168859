.main-login-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    
    width: 100vw;
    height: 100vh;
    background-color: var(--background-color-light);
}


.main-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
    
    width: 100vw;
    height: 100vh;
    background-color: var(--background-color-light);
}
