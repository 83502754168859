.calendar-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;

    background-color: var(--white);
    align-content: center;
    width: 100%;
}

.calendar-container {
    margin: 15px;
    padding: 15px;
    font-size: 14px;
}

.calendar-container{
    background-color: var(--white);
    width: 75%;
}

.calender-container ul {
    margin: 0;
    padding: 0 0 0 1.5em;
}
  
.calender-container li {
    margin: 1.5em 0;
    padding: 0;
}

.calender-container b {
    margin-right: 3px;
}

.calender-container p, ol, ul, dl, table {
    margin: 0 0 0 0;
}